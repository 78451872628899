














































































import { computed, defineComponent, onMounted, reactive, ref, watch } from "@vue/composition-api";
import { Advice, User } from "@/models/model";
import axios from "axios";
import router from "@/router";

interface LocalState {
  user: User;
}

export default defineComponent({
  name: "Contents",
  props: {
    driver_id: {
      type: String,
    },
  },
  setup(props, context) {
    context.emit("show-bar", true);
    const info = ref();
    const calendar = ref();
    const card_height = ref(500);
    const state = reactive<LocalState>({
      user: {
        id: "",
        office: "",
        name: "",
        is_driver: false,
        is_manager: false,
        is_owner: false,
        started_at: "",
        organization: { started_at: "", name: "", drivers_count: 0, plan: "" },
      },
    });
    const advices = ref<Advice[]>([]);

    const iconPath = (template: string) => {
      switch (template) {
        case "advice":
          return require("../assets/advice.svg");
        case "summary_html":
          return require("../assets/summary-html.svg");
        case "summary_pdf":
          return require("../assets/summary-pdf.svg");
        case "special1":
          return require("../assets/special1.svg");
        case "special2":
          return require("../assets/special2.svg");
        case "special3":
          return require("../assets/special3.svg");
        case "special4":
          return require("../assets/special4.svg");
        default:
          return require("../assets/newspaper-outline.svg");
      }
    };

    const calendar_type = ref("month");
    const types = ref([
      { text: "MONTH", value: "month" },
      { text: "WEEK", value: "week" },
      { text: "DAY", value: "day" },
    ]);
    const events = ref([]);
    const calendar_value = ref("");

    const information_visible = ref(false);
    const information_message = ref("");
    const feature_flag_ph3 = process.env.FEATURE_FLAG_PH3 ?? false;
    const loading = ref(true);

    const load = async () => {
      loading.value = true;
      try {
        const login_user = (await axios.get(`${process.env.VUE_APP_CONSUMER_API_ENDPOINT}/login/`, { withCredentials: true })).data;
        const user_id = props.driver_id ? props.driver_id : login_user.id;
        state.user = (await axios.get(`${process.env.VUE_APP_CONSUMER_API_ENDPOINT}/driver/${user_id}/`, { withCredentials: true })).data;
        events.value = (await axios.get(`${process.env.VUE_APP_CONSUMER_API_ENDPOINT}/calendar/${user_id}/`, { withCredentials: true })).data;
        advices.value = (await axios.get(`${process.env.VUE_APP_CONSUMER_API_ENDPOINT}/advice/${user_id}/`, { withCredentials: true })).data;
        document.title = `Telema Coaching - ${state.user.name} 様のコンテンツ一覧`;
      } catch (e) {
        information_message.value = "表示情報の取得に失敗しました。";
        information_visible.value = true;
      }
      loading.value = false;
    };

    watch(calendar_value, (newValue) => {
      // カレンダーの値が変更されたら、URLを更新
      if (newValue) {
        router.replace({
          query: { ...router.currentRoute.query, date: newValue },
        });
      }
    });

    onMounted(async () => {
      // クエリから日付情報がある場合は復元
      if (router.currentRoute.query.date) {
        calendar_value.value = router.currentRoute.query.date as string;
      } else {
        calendar_value.value = "";
      }
      await load();
      onResize();
    });

    const calendar_title = computed(() => {
      const date = calendar.value.parsedValue;
      return `${date.year}年${date.month}月`;
    });

    const onResize = () => {
      if (info.value) {
        if (context.root.$vuetify.breakpoint.mdAndUp) {
          card_height.value = window.innerHeight - info.value.$el.clientHeight - 184;
        } else {
          card_height.value = 200;
        }
      }
    };

    const updateCalendarQuery = () => {
      const currentDate = calendar.value.parsedValue;
      const dateString = `${String(currentDate.date)}`;
      router.replace({
        query: { ...router.currentRoute.query, date: dateString },
      });
    };

    const prevCalendar = () => {
      calendar.value.prev();
      updateCalendarQuery();
    };

    const nextCalendar = () => {
      calendar.value.next();
      updateCalendarQuery();
    };

    const todayCalendar = () => {
      const today = new Date();
      calendar_value.value = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;
      updateCalendarQuery();
    };

    const onClickEvent = (event: any) => {
      if (event.event.id) {
        // 現在のカレンダー情報をクエリパラメータに付与してContentViewへ
        router.push({
          name: "ContentView",
          params: { content_id: event.event.id },
          query: {
            date: calendar_value.value,
          },
        });
      }
    };

    return {
      calendar_type,
      types,
      advices,
      events,
      info,
      card_height,
      state,
      information_visible,
      information_message,
      calendar,
      calendar_value,
      feature_flag_ph3,
      loading,
      calendar_title,
      iconPath,
      onResize,
      prevCalendar,
      nextCalendar,
      todayCalendar,
      onClickEvent,
    };
  },
});
