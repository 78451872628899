

































































































import { defineComponent, onMounted, reactive, ref } from "@vue/composition-api";
import { Advice, ManagedUser, User } from "@/models/model";
import axios from "axios";

interface State {
  users: ManagedUser[];
  user: User;
  advices: Advice[];
}

export default defineComponent({
  name: "Home",
  setup(props, context) {
    context.emit("show-bar", true);

    const info = ref();
    const card_height = ref(500);
    // const advices = ref<Advice[]>(TEST_ADVICES);
    const state = reactive<State>({
      users: [],
      user: {
        name: "",
        office: "",
        is_driver: false,
        is_manager: false,
        is_owner: false,
        organization: { name: "", drivers_count: 0, started_at: "", plan: "" },
      } as User,
      advices: [],
    });

    const iconPath = (template: string) => {
      switch (template) {
        case "advice":
          return require("../assets/advice.svg");
        case "summary_html":
          return require("../assets/summary-html.svg");
        case "summary_pdf":
          return require("../assets/summary-pdf.svg");
        case "special1":
          return require("../assets/special1.svg");
        case "special2":
          return require("../assets/special2.svg");
        case "special3":
          return require("../assets/special3.svg");
        case "special4":
          return require("../assets/special4.svg");
        default:
          return require("../assets/newspaper-outline.svg");
      }
    };

    const loading = ref(true);
    const information_message = ref("");
    const information_visible = ref(false);
    const feature_flag_ph3 = process.env.FEATURE_FLAG_PH3 ?? false;
    const load = async () => {
      loading.value = true;
      try {
        const employees = await axios.get(`${process.env.VUE_APP_CONSUMER_API_ENDPOINT}/employee/`, { withCredentials: true });
        const advices = await axios.get(`${process.env.VUE_APP_CONSUMER_API_ENDPOINT}/advice/`, { withCredentials: true });
        state.user = (await axios.get(`${process.env.VUE_APP_CONSUMER_API_ENDPOINT}/login/`, { withCredentials: true })).data;
        state.users = employees.data;
        state.advices = advices.data;
      } catch (e) {
        information_message.value = "表示情報の取得に失敗しました。";
        information_visible.value = true;
      }
      loading.value = false;
    };
    onMounted(() => {
      onResize();
      load();
    });
    const onResize = () => {
      if (info.value) {
        if (context.root.$vuetify.breakpoint.mdAndUp) {
          card_height.value = window.innerHeight - info.value.$el.clientHeight - 184;
        } else {
          card_height.value = 200;
        }
      }
    };
    return {
      state,
      loading,
      card_height,
      info,
      information_visible,
      information_message,
      feature_flag_ph3,
      iconPath,
      onResize,
    };
  },
});
